import { computed } from 'vue';
import { useAccount as useAccountQuery } from '@/queries/account/useAccount';
import { useFlag } from '@/composables/useFlag';

export function useAccount(accountId) {
  const {
    data: account,
    error,
    isLoading,
    status,
    isSuccess,
  } = useAccountQuery(accountId);

  const apolloBusinessTierFlag = useFlag('apollo_enterprise_business_tier');

  const hasCompletedCustomerQuestionnaire = computed(() => {
    const profileData = account.value?.profile_data;
    if (!profileData.customer_questionnaire_status) return true;

    return profileData.customer_questionnaire_status?.complete === true;
  });

  const hasSoonaStorage = computed(() => {
    //soona storage is currently tied to any subscription (digital tier or fast pass tier). eventually this will change to check for a subscription item on any subscription tier
    return !!account.value?.has_soona_storage;
  });

  const soonaStoragePendingCancellation = computed(() => {
    return !!account.value?.soona_storage_pending_cancellation;
  });

  const soonaStorageCurrentPeriodEnd = computed(() => {
    return account.value?.soona_storage_current_period_end;
  });

  const hasActiveStoreIntegration = computed(() => {
    return account.value?.has_active_store_integration;
  });

  const isDigitalSubscriber = computed(() => {
    return account.value?.is_digital_subscriber;
  });

  const isBusinessSubscriber = computed(
    () =>
      apolloBusinessTierFlag.value &&
      account.value?.subscription?.tier?.slug &&
      account.value?.subscription?.tier?.slug === 'tier-three'
  );

  const eligibleForPlatformSubscriptionTrial = computed(() => {
    return account.value?.eligible_for_platform_subscription_trial;
  });

  const subscription = computed(() => account.value?.subscription ?? {});

  const subscriptionChargedViaStripe = computed(() => {
    return subscription.value?.payment_provider === 'stripe';
  });

  const industryTitle = computed(() => {
    return account.value?.industry?.title;
  });

  const billingAddressId = computed(() => {
    return account.value?.billing_address?.id ?? null;
  });

  return {
    account,
    billingAddressId,
    error,
    isLoading,
    status,
    isSuccess,
    hasActiveStoreIntegration,
    hasCompletedCustomerQuestionnaire,
    hasSoonaStorage,
    eligibleForPlatformSubscriptionTrial,
    soonaStorageCurrentPeriodEnd,
    soonaStoragePendingCancellation,
    isDigitalSubscriber,
    isBusinessSubscriber,
    industryTitle,
    subscriptionChargedViaStripe,
  };
}
