<script setup>
import SidebarSection from './SidebarSection.vue';
import SidebarList from './SidebarList.vue';
import SidebarLink from './SidebarLink.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import UserHub from '@/components/platform-layout/sidebar/UserHub.vue';
import { useFlag } from '@/composables/useFlag';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useSiteNavEvents } from '../useSiteNavEvents';
import { useCapabilities } from '@/composables/useCapabilities';
import { useReservations } from 'src/queries/reservations/useReservations';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { PeriwinkBlue20, PeriwinkBlue70 } from '@/variables.module.scss';
import { useMe } from '@/composables/user/useMe';

defineEmits(['open-user-nav']);

const promoEngineFlag = useFlag('promo_engine');
const trendSetBuilderFlag = useFlag('trend_set_builder');
const apolloDiscoveryPage = useFlag('apollo_discovery_page');
const apolloShowDirectoryToClients = useFlag(
  'apollo_show_directory_to_clients'
);
const toasterEnableUgcTrendPage = useFlag('toaster_enable_ugc_trend_page');

const store = useStore();
const currentAccountId = computed(() => store.state.currentUser?.accountId);
const { myDepartment } = useMe();

//grab a few live bookings in case there are suprise shoots going on
const { data: liveReservationsData } = useReservations(
  {
    accountId: currentAccountId,
    itemsPerPage: 4,
    currentPage: 1,
    filter: 'live',
  },
  // mark as fresh for 1 minute to cache  results a little
  { staleTime: 60 * 1000 }
);

const reservationInProgress = computed(() =>
  liveReservationsData.value?.reservations.find(
    liveReservation =>
      liveReservation.status === 'in_progress' &&
      liveReservation.reservation_type !== 'surprise'
  )
);

const { trackLinkClicked } = useSiteNavEvents();

const capabilitiesRef = computed(() => [
  { capability: 'view_pro_services_directory' },
  { capability: 'soona_staff' },
  { capability: 'ft_soona_staff' },
  { capability: 'manage_trend_sets' },
  { capability: 'manage_packs' },
  { capability: 'manage_crew_booking_builder' },
  { capability: 'view_sidekiq_dashboard' },
  { capability: 'manage_promotions' },
  { capability: 'view_account_tabs' },
  {
    capability: 'manage_catalog_items',
    subjectType: 'account',
    subjectId: currentAccountId.value,
  },
  { capability: 'view_temporary_listing_insights_tool' },
]);

const capabilities = useCapabilities(capabilitiesRef);
</script>

<template>
  <nav class="primary-nav" aria-label="primary" v-bind="$attrs">
    <SidebarSection class="primary-nav__top-section">
      <SoonaButton
        v-if="reservationInProgress"
        element="a"
        size="large"
        :href="`/#/reservation/${reservationInProgress.id}`"
        @on-click="trackLinkClicked('join live', '/reservation')"
      >
        join live
      </SoonaButton>
      <SoonaButton
        v-else
        element="a"
        size="large"
        href="/#/book"
        @on-click="trackLinkClicked('create', '/book')"
      >
        build a shoot
      </SoonaButton>
    </SidebarSection>

    <SidebarSection>
      <SidebarLink
        to="/"
        exact-active
        sidebar-icon="house-smile"
        @click="trackLinkClicked('home', '/')"
      >
        home
      </SidebarLink>
    </SidebarSection>

    <SidebarSection v-if="capabilities.view_account_tabs.hasCapability">
      <template #heading>crew</template>
      <template #default="{ headingId }">
        <SidebarList :aria-labelledby="headingId">
          <SidebarLink
            v-if="capabilities.manage_crew_booking_builder.hasCapability"
            to="/crew/booking/new"
            sidebar-icon="medal"
            data-cypress="concierge-link"
          >
            concierge booking
          </SidebarLink>
          <SidebarLink
            v-if="currentAccountId"
            :to="`/account/${currentAccountId}/assignments`"
            sidebar-icon="star"
            :internal-custom-active="
              ({ path, meta }) =>
                path.startsWith(`/account/${currentAccountId}/`) &&
                meta?.context === 'account dashboard'
            "
          >
            crew profile
          </SidebarLink>
          <SidebarLink
            v-if="capabilities.soona_staff.hasCapability"
            to="/admin"
            sidebar-icon="calendar-user"
          >
            bookings admin
          </SidebarLink>
          <SidebarLink
            v-if="capabilities.ft_soona_staff.hasCapability"
            to="/schedule"
            sidebar-icon="calendar"
          >
            schedule
          </SidebarLink>
          <SidebarLink
            v-if="capabilities.ft_soona_staff.hasCapability"
            to="/shipping_summary"
            sidebar-icon="package"
          >
            shipping summary
          </SidebarLink>
        </SidebarList>
      </template>
    </SidebarSection>

    <SidebarSection>
      <template #heading>make</template>
      <template #default="{ headingId }">
        <SidebarList :aria-labelledby="headingId">
          <SidebarLink
            :to="`/account/${currentAccountId}/bookings`"
            sidebar-icon="camera-alt-1"
            data-cypress="all-bookings"
            :internal-custom-active="
              ({ path }) =>
                path.startsWith(`/account/${currentAccountId}/bookings`)
            "
            @click="trackLinkClicked('bookings', '/account/bookings')"
          >
            studio bookings
          </SidebarLink>
          <SidebarLink
            :to="`/account/${currentAccountId}/media-editor`"
            sidebar-icon="wand-magic-sparkles"
            data-cypress="button-media-editor"
            @click="trackLinkClicked('media editor', `/account/media-editor`)"
          >
            media editor
          </SidebarLink>
          <SidebarLink
            v-if="toasterEnableUgcTrendPage"
            to="/user-generated-content"
            sidebar-icon="ugc"
            :internal-custom-active="
              ({ path }) => path.startsWith(`/user-generated-content`)
            "
            @click="
              trackLinkClicked(
                'user generated content',
                '/user-generated-content'
              )
            "
          >
            user generated content
            <template #right="{ rightClass }">
              <SoonaFlag
                :class="rightClass"
                title="NEW"
                :text-color="PeriwinkBlue70"
                :background-color="PeriwinkBlue20"
                padding-size="0.0625rem 0.25rem"
                type="pill"
              />
            </template>
          </SidebarLink>
          <SidebarLink
            v-if="
              apolloShowDirectoryToClients ||
              capabilities.soona_staff.hasCapability
            "
            to="/directory"
            sidebar-icon="user-heart-alt-1"
            @click="trackLinkClicked('model directory', '/directory')"
          >
            model directory
          </SidebarLink>
          <SidebarLink
            v-if="apolloDiscoveryPage"
            to="/discovery"
            sidebar-icon="lightbulb-alt"
            :internal-custom-active="
              ({ path }) => path.startsWith(`/discovery`)
            "
            @click="trackLinkClicked('discovery', '/discovery')"
          >
            discovery
          </SidebarLink>
        </SidebarList>
      </template>
    </SidebarSection>

    <SidebarSection>
      <template #heading>manage</template>
      <template #default="{ headingId }">
        <SidebarList :aria-labelledby="headingId">
          <SidebarLink
            :to="`/account/${currentAccountId}/gallery`"
            sidebar-icon="image-square"
            :internal-custom-active="
              ({ path }) =>
                path.startsWith(`/account/${currentAccountId}/gallery`)
            "
            data-cypress="button-home-account"
            @click="trackLinkClicked('gallery', `/account/gallery`)"
          >
            gallery
          </SidebarLink>
          <SidebarLink
            v-if="capabilities.manage_catalog_items.hasCapability"
            :to="`/account/${currentAccountId}/products`"
            sidebar-icon="shapes"
            data-cypress="product-catalog"
            :internal-custom-active="
              ({ path }) =>
                path.startsWith(`/account/${currentAccountId}/products`)
            "
            @click="trackLinkClicked('products', '/account/products')"
          >
            products
          </SidebarLink>
        </SidebarList>
      </template>
    </SidebarSection>

    <SidebarSection v-if="apolloDiscoveryPage">
      <template #heading>measure</template>
      <template #default="{ headingId }">
        <SidebarList :aria-labelledby="headingId">
          <SidebarLink
            :to="`/account/${currentAccountId}/listing-insights`"
            sidebar-icon="chart-simple"
            data-cypress="listing-insights"
            :internal-custom-active="
              ({ path }) =>
                path.startsWith(`/account/${currentAccountId}/listing-insights`)
            "
            @click="
              trackLinkClicked('listing insights', '/account/listing-insights')
            "
          >
            listing insights
          </SidebarLink>
          <SidebarLink
            :to="`/account/${currentAccountId}/competitive-analysis`"
            sidebar-icon="binoculars"
            data-cypress="competitive-analysis"
            :internal-custom-active="
              ({ path }) =>
                path.startsWith(
                  `/account/${currentAccountId}/competitive-analysis`
                )
            "
            @click="
              trackLinkClicked(
                'competitive analysis',
                '/account/competitive-analysis'
              )
            "
          >
            competitive analysis
          </SidebarLink>
        </SidebarList>
      </template>
    </SidebarSection>

    <SidebarSection
      v-if="capabilities.ft_soona_staff.hasCapability"
      data-cypress="nav-admin-section"
    >
      <template #heading>admin</template>
      <template #default="{ headingId }">
        <SidebarList :aria-labelledby="headingId">
          <SidebarLink
            to="/admin/dashboard"
            force-external
            sidebar-icon="bones"
          >
            active admin
          </SidebarLink>
          <SidebarLink
            v-if="capabilities.manage_packs.hasCapability"
            :to="'/crew/packs'"
            sidebar-icon="skull-alt"
          >
            pack builder
          </SidebarLink>
          <SidebarLink
            v-if="
              promoEngineFlag && capabilities.manage_promotions.hasCapability
            "
            to="/admin/promotions"
            sidebar-icon="bat"
          >
            promotions
          </SidebarLink>
          <SidebarLink
            v-if="
              (capabilities.view_temporary_listing_insights_tool
                .hasCapability &&
                myDepartment === 'sales') ||
              myDepartment === 'tech'
            "
            to="/admin/temporary-listing-insights"
            sidebar-icon="ghost"
          >
            temporary listing insights
          </SidebarLink>
          <SidebarLink
            v-if="
              trendSetBuilderFlag &&
              capabilities.manage_trend_sets.hasCapability
            "
            to="/admin/trend-sets"
            sidebar-icon="hat-witch"
          >
            trend set builder
          </SidebarLink>
          <SidebarLink
            v-if="capabilities.view_sidekiq_dashboard.hasCapability"
            to="/dev/rollout"
            force-external
            sidebar-icon="cauldron"
          >
            rollout
          </SidebarLink>
          <SidebarLink
            v-if="capabilities.view_sidekiq_dashboard.hasCapability"
            to="/dev/sidekiq"
            force-external
            sidebar-icon="hockey-mask"
          >
            sidekiq
          </SidebarLink>
        </SidebarList>
      </template>
    </SidebarSection>

    <SidebarSection class="primary-nav__bottom-section">
      <UserHub @open-user-nav="$emit('open-user-nav')" />
    </SidebarSection>
  </nav>
</template>

<style lang="scss" scoped>
@use '@/variables';

.primary-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow-y: auto;
  padding-top: 1rem;
  outline-offset: -3px; /* browser outlines are generally in pixels */

  &__top-section {
    padding-bottom: 1rem;
  }

  &__bottom-section {
    margin-top: auto;
    gap: 0;
  }

  .crown-icon {
    color: variables.$tangerine-40;
  }
}
</style>
