import { unref } from 'vue';
import { http } from '@/config/vue-axios';
import { useMutation } from '@tanstack/vue-query';

export function useCreateSalesTax() {
  return useMutation({
    mutationFn: async body => {
      const params = {
        sales_taxes: {
          order_total: unref(body.orderTotal),
          address_id: unref(body.billingAddressId),
        },
      };

      const response = await http.post(`/sales_tax/`, params);
      return response.data;
    },
  });
}
