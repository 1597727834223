import { computed, ref, watchEffect } from 'vue';
import { useCreateSalesTax } from '@/queries/sales_tax/useCreateSalesTax';
import { useAccount as useAccountQuery } from '@/queries/account/useAccount';

export function useSalesTax(accountId, orderTotal) {
  const { data: account } = useAccountQuery(accountId, {
    enabled: computed(() => !!accountId.value),
  });

  const billingAddressId = computed(() => {
    return account.value?.billing_address?.id ?? null;
  });

  const { mutate: calculateSalesTax, isPending, error } = useCreateSalesTax();

  const salesTax = ref(0);

  watchEffect(() => {
    if (orderTotal.value && billingAddressId.value) {
      calculateSalesTax(
        {
          orderTotal: orderTotal.value,
          billingAddressId: billingAddressId.value,
        },
        {
          onSuccess: data => {
            salesTax.value = data;
          },
        }
      );
    }
  });

  return {
    salesTax,
    isPending,
    error,
  };
}
