<script setup>
import { computed, defineAsyncComponent, ref, toRefs } from 'vue';

import {
  AI_SCENES_ACTION,
  AI_PROPS_ACTION,
  BACKGROUND_COLOR_ACTION,
  BLUR_BACKGROUND_ACTION,
  MOBILE_EDIT_ACTION,
  REMOVE_BACKGROUND_ACTION,
  SHADOWS_ACTION,
  RESIZE_ACTION,
  PRODUCTS_ACTION,
  ALBUMS_ACTION,
  NOTES_ACTION,
  RE_EDITS_ACTION,
  PREMIUM_EDITS_ACTION,
} from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';

import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useFlag } from '@/composables/useFlag';

import ActionBarButton from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarButton.vue';
import ActionBarBottom from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarBottom.vue';
import MediaMultiplierLoading from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaMultiplierLoading.vue';
import MediaMultiplierError from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaMultiplierError.vue';
import MediaActionFilter from '@/components/user/anytime/gallery/media-editor/MediaActionFilter.vue';
import RemoveBackground from '@/components/user/anytime/gallery/media-editor/media-multiplier/RemoveBackground.vue';
import ImageResizer from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/ImageResizer.vue';
import { useReservationActionBar } from '@/components/user/anytime/gallery/media-editor/useReservationActionBar';
import { BACKGROUND_COLOR_SYMBOLS } from '@/components/user/anytime/gallery/media-editor/background_color_filter_symbols';
import { RESIZE_SYMBOLS } from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/filter_symbols';
import debounce from 'lodash/debounce';
import DigitalAssetProducts from '@/components/user/anytime/gallery/media-editor/DigitalAssetProducts.vue';
import DigitalAssetCollections from '@/components/user/anytime/gallery/media-editor/DigitalAssetCollections.vue';
import AssetNotes from '@/components/checkout/AssetNotes.vue';
import PremiumEditsDigitalAsset from '@/components/user/anytime/gallery/media-editor/PremiumEditsDigitalAsset.vue';
import { useGetReservationDigitalAssetMediaAddOns } from '@/queries/reservation_digital_assets/useGetReservationDigitalAssetMediaAddOns';
import { useReservationDigitalAsset } from '@/queries/reservation_digital_assets/useReservationDigitalAsset';
import RequestedReEditRDA from '@/components/user/anytime/gallery/media-editor/media-multiplier/RequestedReEditRDA.vue';
import { useMokkerRedirect } from '@/queries/mokker/useMokkerRedirect';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import DigitalAssetNotesDialog from '@/components/notes/DigitalAssetNotesDialog.vue';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  activeAction: {
    type: Symbol,
  },
  asset: {
    type: Object,
    default: undefined,
  },
  iframeUrl: {
    type: String,
    required: false,
  },
  selectionData: {
    type: Object,
    default: () => {},
  },
});

const emits = defineEmits([
  'close-editor',
  'update-action',
  'is-notes-dirty',
  'show-foreground',
  'draw-canvas',
  'show-paywall-dialog',
  'update-selector',
  'refresh-cropper',
  'set-iframe-url',
]);

const { activeAction, asset, iframeUrl, selectionData } = toRefs(props);
const assetId = computed(() => asset.value?.id);
const accountId = computed(() => asset.value?.account_id);

const { currentAccountId } = useMe();
const isMyAccount = computed(() => currentAccountId.value === accountId.value);

const {
  assetUuid,
  foregroundUrl,
  mediaUrl,
  maskUrl,
  pendingEditorTasks,
  reservationId,
  rdaId,
  isPhoto,
  ownedByCustomer,
  ownedBySoona,
  inBag,
} = useMediaEditorDigitalAsset(accountId, assetId);

const enableAIStudio = computed(
  () => ownedByCustomer && !!assetUuid.value && isMyAccount.value
);

const {
  canAdjustBackgroundOnOwnAccount,
  staffCanAdjustBackgroundOnClientAccount,
} = useReservationActionBar({
  accountId,
});

const hasSubscriptionDownloadAccess = computed(
  () =>
    canAdjustBackgroundOnOwnAccount?.value ||
    staffCanAdjustBackgroundOnClientAccount?.value
);

const {
  flyoutHeading,
  flyoutSubheading,
  isAIScenesActive,
  isAIPropsActive,
  isBlurBackgroundActive,
  isImageResizerActive,
  isMobileEditPanelActive,
  isRemoveBackgroundActive,
  isShiftBackgroundColorActive,
  isShadowsActive,
  isProductsActive,
  isAlbumsActive,
  isNotesActive,
  isPremiumEditsActive,
  isReEditRequestActive,
} = useMediaEditor({
  activeAction,
});

const hasToasterDigitalAssetCommentsFlag = useFlag(
  'toaster_digital_asset_comments'
);

const { linkClicked } = useBaseEvents();
const route = useRoute();
const trackSelectedFilter = ref(null);

const refreshCanvas = debounce(() => {
  emits('refresh-cropper');
}, 350);

const trackAndRefreshCropper = async filter => {
  trackSelectedFilter.value = filter;
  refreshCanvas();
};

const clearActiveAction = () => {
  trackSelectedFilter.value = null;
  emits('update-action', undefined);
};

const showBackBtn = computed(() => {
  switch (activeAction.value) {
    case BACKGROUND_COLOR_ACTION:
    case REMOVE_BACKGROUND_ACTION:
    case BLUR_BACKGROUND_ACTION:
    case SHADOWS_ACTION:
    case AI_SCENES_ACTION:
    case AI_PROPS_ACTION:
    case RESIZE_ACTION:
      return true;
    default:
      return false;
  }
});

const showDAPremiumEdits = computed(() => {
  return ownedBySoona.value && rdaId.value;
});

const showOrganize = computed(() => {
  switch (activeAction.value) {
    case PRODUCTS_ACTION:
    case ALBUMS_ACTION:
      return true;
    default:
      return false;
  }
});

const imageResizerComponent = ref();
const shiftBackgroundComponent = ref();
const removeBackgroundComponent = ref();
const blurBackgroundComponent = ref();
const addShadowsComponent = ref();

const handleBackClick = () => {
  trackSelectedFilter.value = null;
  // if we add more subnavs, we might want to make a static .json that we can
  // import and use to look up the 'ancestor' action, instead of hard coding to 'edit'
  emits('update-action', MOBILE_EDIT_ACTION);
};

const handleDrawCanvas = cd => {
  emits('draw-canvas', cd);
};

const AsyncShiftBackgroundColor = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/ShiftBackgroundColor.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});

const AsyncBlurBackground = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/BlurBackground.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});

const AsyncAddShadows = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/AddShadows.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});

const handleButtonClick = (subxActionText, subxActionSymbol) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor menu',
    linkLabel: subxActionText,
    linkHref: null,
  });
  emits('update-action', subxActionSymbol);
};

const handleSaveToGallery = () => {
  if (isShiftBackgroundColorActive.value) {
    shiftBackgroundComponent.value.handleSaveToGallery();
  } else if (isRemoveBackgroundActive.value) {
    removeBackgroundComponent.value.handleSaveToGallery();
  } else if (isBlurBackgroundActive.value) {
    blurBackgroundComponent.value.handleSaveToGallery();
  } else if (isShadowsActive.value) {
    addShadowsComponent.value.handleSaveToGallery();
  } else if (isImageResizerActive.value) {
    imageResizerComponent.value.handleSaveToGallery();
  }
};

defineExpose({ handleSaveToGallery });

// for premium edit filters
const {
  data: mediaAddOns,
  isLoading: mediaAddOnsLoading,
  error: mediaAddOnsError,
} = useGetReservationDigitalAssetMediaAddOns(reservationId, rdaId, {
  enabled: computed(
    () => !!assetId.value && !!reservationId.value && !!rdaId.value
  ),
});

const mediaAddOnLineItemNames = computed(() => {
  if (!mediaAddOns.value) return ['add editing notes'];
  const names = mediaAddOns.value.media_add_on_line_items.map(li => ({
    label: li.product.name,
    disabled: !inBag.value,
  }));
  names.push({ label: 'add editing notes', disabled: !inBag.value });
  return names;
});

// re-edits

const { data: reservationDigitalAsset } = useReservationDigitalAsset(
  {
    reservationId: reservationId,
    rdaId: rdaId,
  },
  {
    enabled: computed(() => !!rdaId.value && !!reservationId.value),
  }
);

// re-edits
const daHasReEditRequest = computed(() => {
  return reservationDigitalAsset.value?.digital_asset
    ?.re_edits_collection_digital_asset?.id;
});

const handleOrganizeFilterChange = filter => {
  const symbolMap = { products: PRODUCTS_ACTION, albums: ALBUMS_ACTION };
  // prevents closing the panel when clicking an already active filter
  if (activeAction.value !== filter) {
    emits('update-action', symbolMap[filter]);
  }
};

const bottomBarSize = computed(() => {
  if (iframeUrl.value) {
    return 'closed';
  } else if (isImageResizerActive.value) {
    return !trackSelectedFilter.value ? 'short' : 'large';
  } else if (isShiftBackgroundColorActive.value) {
    return !trackSelectedFilter.value ? 'short' : 'medium';
  } else if (isPremiumEditsActive.value) {
    return 'large';
  }
  return '';
});

// ai studio iframe
const { mutate: getMokkerRedirect, isPending: aiStudioRedirectLoading } =
  useMokkerRedirect();

const { addToast } = useSoonaToast();

const handleAIStudioButtonClick = (actionText, actionSymbol) => {
  handleButtonClick(actionText, actionSymbol);

  const failureToast = () => {
    addToast(`Failed to open AI studio`, {
      variation: 'error',
    });
  };

  getMokkerRedirect(assetUuid.value, {
    onSuccess: response => {
      if (response.result) {
        const urlSuffix = isAIPropsActive.value ? '&photo_prop=1' : '';
        emits('set-iframe-url', `${response.result}${urlSuffix}`);
      } else {
        failureToast();
      }
    },
    onError: failureToast,
  });
};
</script>

<template>
  <ActionBarBottom
    class="bottom-action-bar"
    :size="bottomBarSize"
    :show-back-button="showBackBtn"
    @back="handleBackClick"
    @close="clearActiveAction"
  >
    <template #heading>{{ flyoutHeading }}</template>
    <template v-if="!activeAction" #primary-nav>
      <ActionBarButton
        v-if="isPhoto"
        icon-name="edit-image"
        label="edit"
        size="small"
        :is-selected="isMobileEditPanelActive"
        @on-click="emits('update-action', MOBILE_EDIT_ACTION)"
      />
      <ActionBarButton
        v-if="showDAPremiumEdits"
        icon-name="pen-swirl"
        label="premium edits"
        size="small"
        :is-selected="isPremiumEditsActive"
        @on-click="emits('update-action', PREMIUM_EDITS_ACTION)"
      />
      <ActionBarButton
        v-if="daHasReEditRequest"
        icon-name="circle-exclamation"
        label="requested re-edit"
        size="small"
        :is-selected="isReEditRequestActive"
        @on-click="emits('update-action', RE_EDITS_ACTION)"
      />
      <ActionBarButton
        icon-name="tag"
        label="organize"
        size="small"
        :is-selected="isProductsActive || isAlbumsActive"
        @on-click="emits('update-action', PRODUCTS_ACTION)"
      />
      <ActionBarButton
        v-if="ownedBySoona"
        :icon-name="
          hasToasterDigitalAssetCommentsFlag
            ? 'message-circle-lines-alt'
            : 'memo-pencil'
        "
        :label="hasToasterDigitalAssetCommentsFlag ? 'comments' : 'notes'"
        size="small"
        :is-selected="isNotesActive"
        @on-click="emits('update-action', NOTES_ACTION)"
      />
    </template>
    <template
      v-else-if="isMobileEditPanelActive && isPhoto && !iframeUrl"
      #secondary-nav
    >
      <ActionBarButton
        icon-name="paint-roller"
        label="background color"
        :enabled="!!maskUrl && !pendingEditorTasks"
        :loading="pendingEditorTasks"
        :is-selected="isShiftBackgroundColorActive"
        @on-click="
          () => handleButtonClick('background color', BACKGROUND_COLOR_ACTION)
        "
      />
      <ActionBarButton
        v-if="isPhoto"
        icon-name="blur"
        label="blur background"
        :enabled="!!maskUrl && !pendingEditorTasks"
        :loading="pendingEditorTasks"
        :is-selected="isBlurBackgroundActive"
        @on-click="
          () => handleButtonClick('blur background', BLUR_BACKGROUND_ACTION)
        "
      />
      <ActionBarButton
        v-if="isPhoto"
        icon-name="shadows"
        label="shadows"
        :enabled="!!maskUrl && !pendingEditorTasks"
        :loading="pendingEditorTasks"
        :is-selected="isShadowsActive"
        is-paywalled
        @on-click="() => handleButtonClick('shadows', SHADOWS_ACTION)"
      />
      <ActionBarButton
        icon-name="scissors"
        label="remove background"
        :enabled="!!foregroundUrl && !pendingEditorTasks"
        :loading="pendingEditorTasks || !foregroundUrl"
        :is-selected="isRemoveBackgroundActive"
        @on-click="
          () => handleButtonClick('remove background', REMOVE_BACKGROUND_ACTION)
        "
      />
      <ActionBarButton
        v-if="isPhoto"
        icon-name="wand-magic-sparkles"
        label="AI scenes"
        :enabled="!!enableAIStudio"
        :is-selected="isAIScenesActive"
        :loading="aiStudioRedirectLoading && isAIScenesActive"
        @on-click="handleAIStudioButtonClick('AI scenes', AI_SCENES_ACTION)"
      >
        <template #content-bottom>
          <small class="u-badge--small action-bar-left__beta">beta</small>
        </template>
      </ActionBarButton>
      <ActionBarButton
        v-if="isPhoto"
        icon-name="hand-holding"
        label="AI props"
        :enabled="!!enableAIStudio"
        :is-selected="isAIPropsActive"
        :loading="aiStudioRedirectLoading && isAIPropsActive"
        @on-click="handleAIStudioButtonClick('AI props', AI_PROPS_ACTION)"
      >
        <template #content-bottom>
          <small class="u-badge--small action-bar-left__beta">beta</small>
        </template>
      </ActionBarButton>
      <ActionBarButton
        v-if="isPhoto"
        icon-name="resize"
        label="resize"
        :enabled="!!mediaUrl"
        :is-selected="isImageResizerActive"
        :show-quest-helper="true"
        @on-click="() => handleButtonClick('resize', RESIZE_ACTION)"
      />
    </template>
    <template v-if="flyoutSubheading" #subheading>
      {{ flyoutSubheading }}
    </template>
    <template v-if="isShiftBackgroundColorActive" #has-own-scroll>
      <MediaActionFilter
        v-if="isShiftBackgroundColorActive"
        :filter-options="BACKGROUND_COLOR_SYMBOLS"
        :show-paywall="!hasSubscriptionDownloadAccess"
        @filter-change="trackSelectedFilter = $event"
      >
        <template #default="{ selectedFilter }">
          <AsyncShiftBackgroundColor
            ref="shiftBackgroundComponent"
            :file="asset"
            :selected-filter="selectedFilter"
            is-mobile-view
            @draw-canvas="handleDrawCanvas($event)"
            @close-editor="emits('close-editor')"
            @close-panel="clearActiveAction"
          />
        </template>
      </MediaActionFilter>
    </template>
    <template v-else-if="isImageResizerActive" #has-own-scroll>
      <MediaActionFilter
        :filter-options="RESIZE_SYMBOLS"
        :show-paywall="!hasSubscriptionDownloadAccess"
        @filter-change="trackAndRefreshCropper"
      >
        <template #default="{ selectedFilter }">
          <ImageResizer
            v-if="asset"
            ref="imageResizerComponent"
            :file="asset"
            :has-freemium-access="!hasSubscriptionDownloadAccess"
            :selected-filter="selectedFilter"
            :selection-data="selectionData"
            is-mobile-view
            @close-editor="emits('close-editor')"
            @update-selector="emits('update-selector', $event)"
            @show-paywall-dialog="emits('show-paywall-dialog')"
            @close-panel="clearActiveAction"
          />
        </template>
      </MediaActionFilter>
    </template>
    <template v-else-if="showOrganize" #has-own-scroll>
      <MediaActionFilter
        default-filter="products"
        :filter-options="['products', 'albums']"
        @filter-change="handleOrganizeFilterChange"
      >
        <DigitalAssetCollections v-if="isAlbumsActive" :asset="asset" />

        <DigitalAssetProducts
          v-if="isProductsActive"
          :account-id="accountId"
          :asset-id="assetId"
          :hide-heading="true"
          :reservation-id="reservationId"
        />
      </MediaActionFilter>
    </template>
    <template v-else-if="isPremiumEditsActive" #has-own-scroll>
      <MediaMultiplierError v-if="mediaAddOnsError">
        {{ mediaAddOnsError }}
      </MediaMultiplierError>
      <MediaMultiplierLoading v-else-if="mediaAddOnsLoading" />
      <MediaActionFilter
        v-else-if="mediaAddOnLineItemNames.length"
        :filter-options="mediaAddOnLineItemNames"
      >
        <template v-if="inBag" #subheading>
          these edits are done by our talented post-production team and will be
          delivered in 24 hrs.
        </template>
        <template #default="{ selectedFilter }">
          <PremiumEditsDigitalAsset
            v-if="isPremiumEditsActive"
            :asset="asset"
            :selected-filter="selectedFilter"
            :reservation-id="reservationId"
            is-mobile-view
            @is-notes-dirty="emits('is-notes-dirty', $event)"
          />
        </template>
      </MediaActionFilter>
    </template>
    <template
      v-else-if="hasToasterDigitalAssetCommentsFlag && isNotesActive"
      #has-own-scroll
    >
      <DigitalAssetNotesDialog
        v-if="accountId && assetId"
        :account-id="accountId"
        :digital-asset-id="assetId"
        @close="clearActiveAction"
      />
    </template>
    <RemoveBackground
      v-if="isRemoveBackgroundActive"
      ref="removeBackgroundComponent"
      :file="asset"
      is-mobile-view
      @show-foreground="emits('show-foreground', $event)"
      @close-editor="emits('close-editor')"
      @close-panel="clearActiveAction"
    />
    <AsyncBlurBackground
      v-if="isBlurBackgroundActive"
      ref="blurBackgroundComponent"
      :file="asset"
      is-mobile-view
      @draw-canvas="handleDrawCanvas($event)"
      @close-editor="emits('close-editor')"
      @close-panel="clearActiveAction"
    />
    <AsyncAddShadows
      v-if="isShadowsActive"
      ref="addShadowsComponent"
      :file="asset"
      is-mobile-view
      @close-editor="emits('close-editor')"
      @draw-canvas="handleDrawCanvas($event)"
      @close-panel="clearActiveAction"
    />
    <AssetNotes
      v-else-if="!hasToasterDigitalAssetCommentsFlag && isNotesActive"
      :asset="asset"
      label=""
      :is-autosave="false"
      @is-notes-dirty="emits('is-notes-dirty', $event)"
    />
    <RequestedReEditRDA
      v-else-if="isReEditRequestActive"
      :key="reservationDigitalAsset?.id"
      :asset="asset"
      :reservation-digital-asset="reservationDigitalAsset"
      is-mobile-view
      @close-panel="clearActiveAction"
    />
  </ActionBarBottom>
</template>
