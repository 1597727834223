<script setup>
import { computed } from 'vue';
import {
  ALBUMS_ACTION,
  NOTES_ACTION,
  PRODUCTS_ACTION,
} from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';

import ActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBar.vue';
import ActionBarButton from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarButton.vue';
import DigitalAssetCollections from '@/components/user/anytime/gallery/media-editor/DigitalAssetCollections.vue';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import DigitalAssetProducts from '@/components/user/anytime/gallery/media-editor/DigitalAssetProducts.vue';
import AssetNotes from '@/components/checkout/AssetNotes.vue';
import NotesList from '@/components/notes/NotesList.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  activeAction: {
    type: Symbol,
  },
  asset: {
    type: Object,
    default: undefined,
  },
  reservationId: {
    type: [Number, String],
    default: null,
  },
});

const emits = defineEmits(['update-action', 'is-notes-dirty']);

const asset = computed(() => props.asset);
const activeAction = computed(() => props.activeAction);

const hasToasterDigitalAssetCommentsFlag = useFlag(
  'toaster_digital_asset_comments'
);

const {
  isAlbumsActive,
  isNotesActive,
  isProductsActive,
  isRightFlyoutOpen,
  flyoutHeading,
} = useMediaEditor({
  activeAction,
});

const handleClick = action => emits('update-action', action);
</script>

<template>
  <ActionBar
    class="right-action-bar"
    :is-flyout-open="isRightFlyoutOpen"
    position="right"
  >
    <ActionBarButton
      column="right"
      icon-name="album"
      :is-selected="isAlbumsActive"
      label="albums"
      data-cypress="digital-asset-collections-action"
      @on-click="handleClick(ALBUMS_ACTION)"
    />
    <template #flyout-heading> {{ flyoutHeading }} </template>

    <ActionBarButton
      column="right"
      icon-name="shapes"
      :is-selected="isProductsActive"
      label="products"
      data-cypress="digital-asset-products-action"
      @on-click="handleClick(PRODUCTS_ACTION)"
    />
    <ActionBarButton
      v-if="asset && asset.ownership !== 'customer'"
      column="right"
      :icon-name="
        hasToasterDigitalAssetCommentsFlag
          ? 'message-circle-lines-alt'
          : 'memo-pencil'
      "
      :is-selected="isNotesActive"
      :label="hasToasterDigitalAssetCommentsFlag ? 'comments' : 'notes'"
      @on-click="handleClick(NOTES_ACTION)"
    />

    <template #flyout-content>
      <DigitalAssetCollections v-if="isAlbumsActive" :asset="asset" />
      <DigitalAssetProducts
        v-if="isProductsActive"
        :account-id="asset?.account_id"
        :asset-id="asset?.id"
        :hide-heading="true"
        :reservation-id="reservationId"
      />
      <AssetNotes
        v-else-if="!hasToasterDigitalAssetCommentsFlag && isNotesActive"
        class="right-action-bar__notes"
        :asset="asset"
        label="notes"
        :is-autosave="false"
        @is-notes-dirty="emits('is-notes-dirty', $event)"
      />
      <NotesList
        v-else-if="hasToasterDigitalAssetCommentsFlag && isNotesActive"
        subject-type="digital_assets"
        :subject-id="asset?.id"
      />
    </template>
  </ActionBar>
</template>
